@import 'index/menu';
@import 'index/center';
@import 'index/banners';

main {

    margin: 10 auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .title {
    
    background-color: #2e7cbf;
    font-family: Roboto-BoldCondensedItalic;
    font-size: 1rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0px;
    box-sizing: border-box;

    }

    .left,
    .right {

        width: 15%;
        height: auto;
        flex-grow: 1;

    }

    .map {

        height: 200px;
        margin-top: 20px;

    }

}
