.payment {

    display: flex;
    flex-direction: column;
    padding: 30px;

    &-block {

        display: flex;
        flex-direction: column;

        &__row {

            display: flex;
            align-items: center;
            margin-bottom: 30px;
            flex-wrap: wrap;

            &_between {
            
                justify-content: space-between;
            
            }

            &_no-wrap {
            
                flex-wrap: nowrap;

            }

        }

        &__number {

            padding: 2px 7px;
            background-color: #2e7cbf;
            color: #fff;
            border-radius: 50%;
            margin-right: 22px;

        }

        &__title {

            font-size: 24px;
            color: #003049;
            
        }

        &__input {
        
            max-width: 180px;
            width: 100%;
            padding: 12px 16px;
            border: 1px solid #a1a1a1;
            color: #a1a1a1;
            margin-bottom:  15px;
        
        }

        &__select {
        
            max-width: 180px;
            width: 100%;
            height: 45px;
        
        }

        &__checkbox {
        
        margin-right: 15px;
        
        }

        &__text {
        
            color: #a1a1a1;
        
        }

        &__link {
        
            color: #335eff;
            margin-left: 5px;
        
        }

    }

    &-item {

        display: flex;
        flex-direction: column;
        padding: 15px 0;
        max-width: 200px;
        width: 100%;
        border: 1px solid #eee;
        box-shadow: 0px 1px 8px -4px #000;
        background-color: #fff;
        align-items: center;
        margin-bottom: 55px;
        cursor: pointer;
        transition: .6s ease;

        &_active {

            transition: .6s ease;
            background-color: #2e7cbf;

            .payment-item__title,.payment-item__currency,.payment-item__value {

                color: #fff;
                transition: .6s ease;

            }

            .payment-item__title {

                border-color: #fff;
                transition: .6s ease;
                border-color: #fff;

            }

            .payment-item__button {

                background-color: #fff;
                color: #2e7cbf;
                transition: .6s ease;
                cursor: pointer;

            }


        }

        &:hover {
        
            transition: .6s ease;
            background-color: #2e7cbf;

            .payment-item__title,.payment-item__currency,.payment-item__value {

                color: #fff;
                transition: .6s ease;

            }

            .payment-item__title {

                border-color: #fff;
                transition: .6s ease;
                border-color: #fff;

            }

            .payment-item__button {

                background-color: #fff;
                color: #2e7cbf;
                transition: .6s ease;

            }
 
        }

        &__wrapper {

            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            flex-wrap: wrap;

        }

        &__title {

            color: #003049;
            font-size: 18px;
            padding: 10px;
            box-sizing: border-box;
            border-bottom: 2px solid #e3e3e3;
            width: 100%;
            text-align: center;

        }

        &__value {
        
            font-size: 30px;
            font-weight: 700;
            color: #003049;
            margin-bottom: 12px;
            margin-top: 33px;

            &_total {
            
                font-size: 24px;
                margin: 0px;

            
            }
        
        }

        &__currency {
        
            font-size: 16px;
            color: #c2c2c2;
            margin-bottom: 30px;
        
        }

        &__button {
        
            background-color: #2e7cbf;
            width: 85%;
            height: 46px;
            color: #fff;
            border: none;
            outline: none;
            cursor: pointer;
            display: block;

            &:hover {

                background-color: #fff;
                color: #2e7cbf;
                transition: .6s ease;

            }

            &:active {
            
                outline: none;
            
            }


            &_total {
            
                width: 55%;
                margin-top: 15px;

                &:hover {
                
                    border: 1px solid #2e7cbf;
                
                }

            }
        
        }

    }

}
