footer {

    width: 100%;
    height:auto;
    margin-bottom: 0px;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    background-image: url(images/footer1a.png);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background: rgba(68,159,239,1);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#449fef', endColorstr='#1f77be', GradientType=0 );

    .results {
        color: #fff;
        
        > * {
            margin-bottom: 20px;

            > * {
                display: block;
                margin-bottom: 5px;
            }

            > br {
                display: none;
            }
        }
    }
}

footer .foot_main {

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-bottom: 30px;

    .lft,
    .rght {

        width: 50%;
        height: auto;
        position: relative;
        flex-grow: 1;

        h2 {

            font-family: Roboto-BoldCondensedItalic;
            color: white;
            font-size: 1rem;
            text-transform: uppercase;
            padding: 15px;
            text-align: left;
            box-sizing: border-box;

        }

    }

    .lft {

        border-right: 1px solid rgba(255,255,255,0.3);
        width: 250px;
        padding-right: 10%;
        text-align: right;
        padding-bottom: 10px;

        input[type="text"] {

            width: 100%;
            height: 27px;
            padding: 0px 5px;
            box-sizing: border-box;
            background: white;

        }

        textarea {

            width: 100%;
            height: 100px;
            padding: 5px;
            resize: none;
            box-sizing: border-box;
            background: #fff;

        }

        input[type="submit"] {

            height: auto;
            display: inline-block;
            text-align: right;
            border: none;
            padding: 10px 20px;
            color: white;
            font-family: Roboto-Medium;
            text-transform: uppercase;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
            background: rgba(109,198,92,1);
            background: linear-gradient(to bottom, rgba(109,198,92,1) 0%, rgba(67,181,44,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6dc65c', endColorstr='#43b52c', GradientType=0 );
            margin-bottom: 20px;

            @media screen and (max-width: 39.9375em) {
                display: block;
                margin: 0 auto 20px;
            }
        }

        a {
            width: auto;
            display: inline-block;
            vertical-align: middle;
            font-family: Roboto-Light;
            font-size: 11pt;
            cursor: pointer;
            color: white;
            text-transform: uppercase;
            margin-right: 125px;
        
            @media screen and (max-width: 39.9375em) {
                display: block;
                text-align: center;
                margin: 0 auto;
            }
        }

        .agree-wrapper {
            display: flex;
            margin: 20px 0;

            input {
                margin-right: 10px;
            }
        }

        label {
            display: block;
            color: #fff;
            text-align: left;
        
            a {
                display: inline;
                margin-right: 0;
                font-family: inherit;
                color: #6dc65c;
                font-size: inherit;
                text-transform: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }
        } 

        form .g-recaptcha {

            width: 100%;

            * {
                max-width: 100% !important;
            }
        }

    }

    .rght {

        padding: 0 20px;

        .text {

            font-family: Roboto-MediumItalic;
            color: white;
            font-size: 1rem;
            padding-bottom: 20px;
            box-sizing: border-box;
            text-align: left;

        }


        form > label {

            width: 100%;
            height: auto;
            display: block;
            font-family: Roboto-Light;
            font-size: 0.875rem;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;
            box-sizing: border-box;
            text-align: left;
            color: white;
            cursor: pointer;

            &:before {

                content: "";
                height: 8px;
                width: 8px;
                background-color: white;
                position: absolute;
                top: 22%;
                left: 6px;

            }

        }

        .mn {

            width: 100%;
            display: inline-block;
            vertical-align: middle;
            font-size: 0px;


            label {

                width: 50%;
                width: auto;
                display: inline-block;
                vertical-align: middle;
                font-family: Roboto-Light;
                font-size: 0.875rem;
                cursor: pointer;
                color: white;
                text-transform: uppercase;
                margin-right: 30px;


            }
        }

        .mn input[type="checkbox"] {
           
            display: none;
        
        }

        .mn  input[type="checkbox"]:checked~#results{

            display: block;

        }

        #variant1:checked~#label1{
            color: #071b5a;
        }
        #variant2:checked~#label2{
            color: #071b5a;
        }
        #variant3:checked~#label3{
            color: #071b5a;
        }
        #variant1:checked~#label1:before{
            background-color: #cfd233;
        }
        #variant2:checked~#label2:before{
            background-color: #cfd233;
        }
        #variant3:checked~#label3:before{
            background-color: #cfd233;
        }

        .checkbox:checked + label{
            color:#071b5a;
        }

        .checkbox:checked + label:before{
            background-color:#cfd233!important;
        }

        .mn input[type="submit"]{

            height: auto;
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            border: none;
            padding: 10px 20px;
            color: white;
            font-family: Roboto-Medium;
            text-transform: uppercase;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
            background: rgba(109,198,92,1);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6dc65c', endColorstr='#43b52c', GradientType=0 );

        }

    }

    #results{

        display: none;
        position: absolute;
        min-width: 100px;
        min-height: 100px;
        width: auto;
        height: auto;
        top: 30px;
        // left: -300px;
        background-color: #F1F7FD;
    
    }

    #results table{
     
        font-size: 0.875rem;
    
    }


    .rght .openstat {

        position: absolute;
        bottom: 0px;
        right: 0px;

    } 

}

.footer_down {

    width: 100%;
    height: auto;
    padding:20px;
    box-sizing: border-box;

    h2 {

        font-family: Roboto-BoldCondensedItalic;
        color: white;
        font-size: 1rem;
        text-transform: uppercase;
        padding-left: 50px;
        text-align: left;

    }

    .photo-slider {

        width: 100%;
        height: auto;
        padding: 0px 40px;
        box-sizing: border-box;
        position: relative;

        .controls {
        
            // position: relative;
        
        }

        .controls .control {

            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            width: 50px;
            height: 100%;
        }

        .controls .prev-photo {

            background-image: url(../images/left_foot.png); 
            left: -30px;

        }
        .controls .next-photo {

            background-image: url(../images/right_foot.png); 
            right: -30px;

        }



    }

    .photo-slider input[type="button"] {

        width: 20px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 30%;
        border: none;
        background-color: transparent;
        opacity: 1;

    }

    .photo-slider .slider_album {

        width: 100%;
        height: auto;
        display: inline-block;
        vertical-align: top;
        font-size: 0px;
        transition: 0.2s;


        .slide {
        
            width: 100%;
        
        }

        .slide .items-photo { 

            width: 24%;
            height: 112px;
            margin-right: 1%;
            display: inline-block;
            vertical-align: top;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            background-image:url("../images/slide_f1.jpg");

        }

        a:last-shild .slide {
 
            margin-right: 0px;

        }

    }

}
