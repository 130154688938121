.custom-select {
  position: relative;
  width: 100%;
  max-width: 230px;
  margin-bottom: 10px;


}
.custom-select select {
    display: none; /*hide original SELECT element:*/
}
.select-selected {
  background-color: #fff;
}
/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  background: url('/images/down.png') no-repeat center;
  background-size: contain;
  width: 20px;
  height: 10px;
  top: 43%;
  right: 10px;
  transition: .6s ease;
}
/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {

    transform: rotateX(180deg);
    transition: .6s ease;

}
/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #a1a1a1;
  padding: 12px 16px;
  border: 1px solid #a1a1a1;
  cursor: pointer;
  user-select: none;
}
/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}
/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
