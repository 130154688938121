main .mid {

    width: 50%;
    height: auto;
    flex-grow: 1;
    margin: 0px 10px;
    background-color: #f1f7fd;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.5);
    position: relative;

    .news {

        width: 100%;
        height: auto;

    }

    .content {

        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;

    }

    .news .pages {

        position: absolute;
        bottom: 0px;
        width: 100%;
        padding-bottom: 15px;
        box-sizing: border-box;

    }

    .news .up {

        width: 100%;
        height: auto;
        font-size: 0px;
        display: inline-block;
        vertical-align: top;

        a {

            text-transform: uppercase;
            font-family: Roboto-Light;
            font-size: 1rem;

        }

        .archive,
        .fresh {

            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding: 7px 10px;
            box-sizing: border-box;

        }

        .archive {

            text-align: left;

            a {

                color: #292a2a;

            }

        }

        .fresh {

            text-align: right;

            a {

                color: #92bff4;

            }

        }

    }

    .news .el {

        width: 100%;
        height: auto;
        text-align: left;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 15px;
    }

    .news .el:last-child {
    
    margin-bottom: 0px;
    
    }

    .news .el {

        .content {

            width: 100%;
            height: auto;
            border-top: 6px solid #62a7f0;

        }

        .content .date {

            color: #8abdf4;
            text-align: right;
            display: inline-block;
            font-size: 0.75rem;
            padding: 15px 0px;
            box-sizing: border-box;

        }

        .content .text {

            font-size: 12px;
            font-family: Roboto-Light;
            color: #525470;


        }

        .content .btn {

            width: 100%;
            height: auto;
            text-align: right;

            a {

                width: 100px;
                height: auto;
                padding: 10px 20px;
                text-transform: uppercase;
                font-family: Roboto-Medium;
                text-decoration: none;
                box-sizing: border-box;
                display: inline-block;
                color: white;
                margin: 15px 0px;
                box-shadow: 1px 1px 3px rgba(0,0,0,0.6);
                background: rgba(71,158,239,1);
                background: linear-gradient(to bottom, rgba(71,158,239,1) 0%, rgba(31,119,190,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#479eef', endColorstr='#1f77be', GradientType=0 );

            }

        }

        .content .leftimg {

            width: 150px;
            height: 100px;
            margin: 0 7px 7px 0;
            float: left;
            display: inline-block;
            text-align: center;
            background-image: url(/images/logo.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            & > img { display: none; }

        }

    }

}
