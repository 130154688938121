header {

    background-color: rgb(46, 124, 191);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.7);

}

header .up {
    
    display:flex;
    width: 100%;
    height: auto;
    background-image: url(../images/img1a.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    vertical-align: top;
    font-size: 0px;
    padding: 5px 10px;
    position: relative;
    box-sizing: border-box;

    .text {

        width: 80%;
        height: auto;
        display: inline-block;
        vertical-align: top;
        text-align: right;
        padding: 10px 0px;
        padding-right: 20px;
        box-sizing: border-box;

        p:first-child{

            font-size: 20px;
           
        }

        p {
        
            font-size: 14px;
            font-family: Roboto-Medium;
            color: white;
            text-shadow: #25599a 1px 0px, #25599a 1px 1px, #25599a 0px 1px, #25599a -1px 1px, #25599a -1px 0px, #25599a -1px -1px, #25599a 0px -1px, #25599a 1px -1px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px;


        
        }

        h3 {

            font-size: 1.25rem;
            font-family: Roboto-BoldCondensedItalic;
            color: white;
            padding: 10px 0px;
            line-height: 1.5;
            box-sizing: border-box;
            text-shadow: #25599a 1px 0px, #25599a 1px 1px, #25599a 0px 1px, #25599a -1px 1px, #25599a -1px 0px, #25599a -1px -1px, #25599a 0px -1px, #25599a 1px -1px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px;

        }

    }
    
    .img_logo{
            
        width:20%;
        flex-wrap:wrap;
        display:flex;
        
        a{
            width:100%;
        }
        .logo, .cal_logo {

            width: 100%;
            vertical-align: top;
            background-image: url(../images/logo.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height:100%;
        }
        .cal_logo{
            height:100%;
            background-image:url(../images/kalll.png);
        }
    
    }
    img {
    
    position: absolute;
    right: 70px;
    bottom: -4px;

    }


}

header .down {

    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    > div {

        > * {
            margin-left: 30px;

            &:first-child { margin-left: 0; }
        }

        > .but-accessibility,
        > .education-payment {

            font-size: 18px;
            font-family: Roboto-Medium;
            color: white;
            text-decoration: none;
            text-shadow: #25599a 1px 0px, #25599a 1px 1px, #25599a 0px 1px, #25599a -1px 1px, #25599a -1px 0px, #25599a -1px -1px, #25599a 0px -1px, #25599a 1px -1px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px, #25599a 0 0 3px;
            background-repeat: no-repeat;
            background-position: 0 center;
            background-size: 30px;
            padding: 5px 0 5px 40px;

        }

        > .but-accessibility {

            border: none;
            background-image: url("../images/eye.png");
            cursor: pointer;

        }

        > .education-payment {

            background-image: url("../images/university-graduate-hat.svg");

        }

    }

    form {

        width: 50%;

    }

    .label {

        width: 30%;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        font-size:  0.6875rem;
        color: white;
        font-family: Roboto-Light;
        text-transform: uppercase; 

    }

    .search {

        width: 100%;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        border: 1px solid transparent;

        input[type="text"] {

            width: 100%;
            height: 30px;
            background-color: rgba(255,255,255,0.9);
            box-shadow: inset 1px 1px 3px #757575;
            border: none;
            box-sizing: border-box;
            padding-left: 5px;
            padding-right: 35px;

        }

        input[type="submit"] {

            width: 30px;
            height: 30px;
            background-image: url(../images/search.png);
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: center;
            position: absolute;
            top: 0px;
            right: 0px;
            border: none;
            background-color: transparent;

        }
    }

}

header .menu {

    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    min-height: 40px;

    .g-burger-acc {

        background-image: url(../images/burger_white.svg);
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        position: absolute;
        top: 6px;
        background-position: center;
        left: 10px;
        display: none;

    }

    .mob-nav {

        font-family: Roboto-BoldCondensedItalic;
        font-size: 1em;
        color: white;
        text-transform: uppercase;
        box-sizing: border-box;
        text-align: left;
        border: none;
        display: none;
        background: none;
        background-image: url("/images/arrow.svg");
        background-repeat: no-repeat;
        background-size: 35% 35%;
        background-position: right;
        min-width: 80px;

    }

    > .tab-frame > ul {

        height: auto;
        padding: 0px;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        margin-bottom: 0px;
        background-image: url(images/menu1a.png);
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        background: rgba(72,163,242,1);
        background: linear-gradient(to bottom, rgba(72,163,242,1) 0%, rgba(37,129,204,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48a3f2', endColorstr='#2581cc', GradientType=0 );


        > li {

            width: auto;
            height: auto;
            flex-grow: 1;
            box-sizing: border-box;
            padding: 7px 0px;
            position: relative;
            display: inline-flex;

            @media screen and (max-width: 39.9375em) {
                display: block;
            }
        }

        > li:first-child > a {

            border-left: none;

        }

        >li a {

            font-family: Roboto-Medium;
            color: white;
            text-transform: uppercase;
            display: block;
            font-size: 0.8125rem;
            padding: 15px 8px;
            border-left: 1px solid rgba(255,255,255,0.5);
            box-sizing: border-box;
            text-align: center;


        }

        >li:hover {

            background-color: white;

            > a {

                color: #071c5b;
                text-decoration: none;


            }

            > ul {

                min-width: 100%;
                display: block;

            }

        }
        >li:hover:after {

            content: "";
            width: 20px;
            height: 10px;
            position: absolute;
            bottom: 5px;
            left: 45%;
            background-image: url(../images/menu_ico.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @media screen and (max-width: 39.9375em) {
                display: none;
            }
        }

    }

    > .tab-frame > ul > li > ul {

        padding: 0px;
        height: auto;
        box-sizing: border-box;
        list-style-type: none;
        position: absolute;
        top: 100%;
        left: 0px;
        border-left: 6px solid #65aefa;
        background-color: rgba(255,255,255,0.7);
        display: none;

        @media screen and (max-width: 39.9375em) {
            position: static;
            display: block;
        }

        > li {
        
            width: 100%;
            height: auto;
            text-align: left;
            display: block;
            box-sizing: border-box;
            position: relative;
            border-bottom: 1px solid #b2b7be;

        }

        > li:hover:after {

            content: "";
            width: 7px;
            height: 7px;
            position: absolute;
            top: 15px;
            left: 5px;
            background-color: #486ba2;

        }

        >li > a {

            font-family: Roboto-LightItalic;
            font-size: 0.8125rem;
            color: #334479;
            text-transform: uppercase;
            display: block;
            padding: 10px 5px;
            padding-left: 15px;
            box-sizing: border-box;


        }

        > li > a:hover {

            color: #42b52b;
            text-decoration: none;

        }

    }


}

header .top-slider {

    position: relative;


    .slider {

        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        font-size: 0;
        height: auto;
        width: 100%;
        position: relative;
        box-shadow: 0px -5px 17px rgba(0,0,0,0.7);


        .slide {

            width: 100%;
            height: auto;
            display: inline-block;
            vertical-align: top;
            font-size: 0px;
            position: relative;
            transition: 0.2s;
            box-sizing: border-box;

        }

        .slide img {

            width: 100%;
            height: 300px;

        }

    }

    .controls {

        awidth: auto;
        height: auto;
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: inline-block;
        vertical-align: middle;
        z-index: 1;



        .prev-main,
        .next-main {

            background-position: center;
            background-repeat: no-repeat;
        }

        .prev-main{
        
            background-image: url(../images/left_foot.png);
        }
        .next-main{
        
            background-image: url(../images/right_foot.png);
        }

        .control {

            width: 25px;
            height: 25px;
            // background-color: #98b6d4;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            cursor: pointer;

        }

        .control:last-child {
        
            margin-right: 0px;
        
        }

        .active {

            background-color: #f7e60f;
            box-shadow: 1px 1px 2px black;

        }

    }

}
