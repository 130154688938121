.right {

    background-color: #f1f7fd;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.5);
    position: relative;
    padding-bottom: 50px;
    box-sizing: border-box;

    .btn_up,
    .btn_down{

        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

    }

    .btn_up {

        margin-top: 10px;
        background-image: url(images/up.png);

    }

    .btn_up_down {

        background-image: url(images/down.png);

    }

    .banners {

        width: 100%;
        height: 100%;
        overflow: hidden;

        .view {

            width: 100%;
            height: auto;
            position: relative;
            top: 0px;
            transition: 0.15s;

        }

    }

    .banners .banner {

        width: 100%;
        height: auto;
        text-align: center;
        padding: 5px 10px;
        box-sizing: border-box;

        img {

            width: 100%;
            height: 60px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

        }

        a {

            width: auto;
            font-family: Roboto-Medium;
            color: #6c6e70;

        }

        a:hover {

            text-decoration: underline;
            color: #6f9fcf;

        }

    }

    .celebr {

        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0px;

        p {

            display: block;
            width: 100%;
            height:50px;
            color: white;
            background-color: #2e7cbf;
            padding: 15px 0px;
            box-sizing: border-box;
            font-family: Roboto-BoldCondensedItalic;
            text-transform: uppercase;

        }

        p a {

            color: #feef00;
            text-decoration:underline;
            font-family: Roboto-BoldCondensedItalic;
            text-transform: uppercase;

        }

    }
}
