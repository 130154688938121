@import 'header';
@import 'index';
@import 'footer';
@import 'payment';
@import 'select';

*{
    font-family: arial;
    outline: none;
}
p{
    padding: 0;
    margin: 0;
    /*/*font-size: 11pt;*/
    font-size: 0.875rem;
}
a{
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: blue;
    /*/*font-size: 11pt;*/
    font-size: 0.875rem;
}
a:hover{

    // text-decoration: underline;

}
h1{
    margin: 0;
    padding: 0;
    /*font-size: 24px;*/
    font-size: 1.5rem;
}
h2{
    margin: 0;
    padding: 0;
    /*font-size: 22px;*/
    font-size: 1.375rem;
}
h3{
    margin: 0;
    padding: 0;
    font-size: 18px;
}

header,
main,
footer {

    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

}

main {

    margin: 5px auto 15px auto;

}
body {

    // max-width: 1000px;
    width: 100%;
    height: auto;
    margin: 0px auto;
    // background-image: url(images/bg.jpg);
    background-image: url(../images/bg.jpg);

}

.m-calendar-block {

    padding:5px;
    padding-top: 20;

    .present-day {

        width: 100%;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
        border: 1px dotted silver;
        box-sizing: border-box;

        .text-gray {

            color: #575757;

        }

    }
    .m-title{
        font-family: MyriadPro-Regular;
        font-weight: 900;
        font-size: 24px;
        color: #015293;
    }
}

.info-block {

    width: 100%;
    margin-top: 20px;
    display: flex;

    .info-date-block {

        width: 110px;
        height: 110px;
        flex-shrink: 0;
        background-image: url(/images/background/calendar.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;

        .date-mount,
        .date-day {

            width: 100%;
            padding-top: 10px;
            display: inline-block;
            text-align: center;
            box-sizing: border-box;

        }

        .date-mount {

            height: 30%;
            font-weight: 600;
            color: white;

        }

        .date-day {

            height: 70%;
            font-size: 48px;
            font-weight: 900;
            color: #015293;

        }

    }

    .m-block-more {

        width: 100%;
        box-sizing: border-box;

        .m-title-gray {

            padding: 10px;

        }

        p {

            padding: 0px 10px 10px;
            font-size: 16px;
            color: #575757;
            box-sizing: border-box;

        }

    }

    .m-link-more {

        width: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        border-top: 1px solid #e6e6e6;

        > a {

            cursor: pointer;

        }

    }

}
