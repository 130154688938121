.left .v_menu {

    width: 100%;
    height: auto;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.5);

    ul {

        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;
        background-color: #f1f7fd;
        list-style-type: none;

    }

    ul li {

        width: 100%;
        height: auto;
        position: relative;
        padding:0px 25px;
        box-sizing: border-box;
        text-align: left;

    }

    ul li:hover {

        background-color: #2e7cbf;

        a {

            color: white;
            border: none;
            padding-bottom: 16px;

        }

        &:before {

            background-color: #cfd233;

        }

    }

    ul li:before {

        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 40%;
        left: 10px;
        background-color: #515370;

    }

    ul li a {

        display: block;
        width: 100%;
        height: auto;
        padding: 15px 0px;
        border-bottom: 1px solid #c1c6ca;
        font-family: Roboto-Light;
        color: #5e617c;
        font-size: 0.875rem;
        text-decoration: none;

    }

    ul li:last-child a {

        border-bottom: none;

    }

}
